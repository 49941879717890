import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions.js'
import mutations from './mutations.js'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    alert: {
      active: false,
      message: 'This is a basic alert',
      level: 'alert-info'
    },
    analyzedGeoJson: false,
    rasterBreadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    rasterDataset: {},
    rasterDatasets: [],
    rasterFolder: {
      id: 'root',
      name: 'Root'
    },
    rasterFolders: [],
    pointBreadcrumbs: [
      {
        id: 'root',
        name: 'Root'
      }
    ],
    pointDataset: {},
    pointDatasets: [],
    pointFolder: {
      id: 'root',
      name: 'Root'
    },
    pointFolders: [],
    posting: false,
    farm: {},
    mapObject: {},
    spinner: false
  },
  actions: actions,
  mutations: mutations
})
