<template>
  <div id="dataset-pisker" class="card p-1">
    <p class="text-center mb-1">Choose a Dataset</p>
    <div v-if="mode === 'raster'">
      <div
        class="mb-2"
      >
        <span
          v-for="(crumb, i) in rasterBreadcrumbs"
          v-bind:key="i"
        >
          <b-badge
            pill
            variant="primary"
            @click="launchFolder(crumb, true)"
            class="clickable"
          >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== rasterBreadcrumbs.length"> / </span>
        </span>
      </div>
      <!-- FOLDERS -->
      <div class="list-group mb-2 folders">
        <div
          v-for="(folder, i) in $store.state.rasterFolders"
          v-bind:key="i"
          class="list-container"
        >
          <a
            @click="launchFolder(folder)"
            class="list-group-item list-group-item-action clickable mb-2 folder"
          >
            <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
            <p class="mb-1">{{ folder.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
      <!-- DATASETS -->
      <div class="list-group sets">
        <div
        v-for="(dataset, i) in $store.state.rasterDatasets"
        v-bind:key="i"
        class="list-container"
        >
          <a
            @click="launchDataset(dataset)"
            class="list-group-item list-group-item-action clickable handle mb-2"
          >
            <p class="lead mb-1"><i v-if="dataset.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i> {{ dataset.name }}</p>
            <p class="mb-1">{{ dataset.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
    </div><!-- v-if -->
    <div v-if="mode === 'point'">
      <div
        class="mb-2"
      >
        <span
          v-for="(crumb, i) in pointBreadcrumbs"
          v-bind:key="i"
        >
          <b-badge
            pill
            variant="primary"
            @click="launchFolder(crumb, true)"
            class="clickable"
          >{{ crumb.name }}</b-badge><span v-if="(i + 1) !== pointBreadcrumbs.length"> / </span>
        </span>
      </div>
      <!-- FOLDERS -->
      <div class="list-group mb-2 folders">
        <div
          v-for="(folder, i) in $store.state.pointFolders"
          v-bind:key="i"
          class="list-container"
        >
          <a
            @click="launchFolder(folder)"
            class="list-group-item list-group-item-action clickable mb-2 folder"
          >
            <p class="lead mb-1"><i class="fas fa-folder"></i> {{ folder.name }}</p>
            <p class="mb-1">{{ folder.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
      <!-- DATASETS -->
      <div class="list-group sets">
        <div
        v-for="(dataset, i) in $store.state.pointDatasets"
        v-bind:key="i"
        class="list-container"
        >
          <a
            @click="launchDataset(dataset)"
            class="list-group-item list-group-item-action clickable handle mb-2"
          >
            <p class="lead mb-1"><i v-if="dataset.dynamic" class="fas fa-satellite-dish" v-b-tooltip.hover.top="'Dynamic Dataset'"></i> {{ dataset.name }}</p>
            <p class="mb-1">{{ dataset.description }}</p>
          </a>
        </div>
      </div><!-- /.list-group -->
    </div><!-- v-if -->
  </div><!-- /#dataset-picker -->
</template>

<script>
import axios from 'axios'

export default {
  props: [
    'mode'
  ],
  computed: {
    rasterBreadcrumbs: {
      get () {
        return this.$store.state.rasterBreadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'rasterBreadcrumbs', value: value })
      }
    },
    pointBreadcrumbs: {
      get () {
        return this.$store.state.pointBreadcrumbs
      },
      set (value) {
        this.$store.commit('setStateProperty', { property: 'pointBreadcrumbs', value: value })
      }
    }
  },
  created () {
    this.fetchDatasets()
    this.fetchFolders()
  },
  methods: {
    fetchDatasets () {
      const timeStamp = new Date().getTime()
      let datasetsQuery = false
      if (this.mode === 'raster') {
        datasetsQuery = 'https://api.efficientvineyard.com/datasets/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.rasterFolder.id
      }
      if (this.mode === 'point') {
        datasetsQuery = 'https://api.efficientvineyard.com/datasets/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.pointFolder.id
      }

      axios.get(datasetsQuery).then(response => {
        const responseData = []
        response.data.forEach(responseItem => {
          if (this.mode === 'raster' && responseItem.file.raster) {
            responseData.push(responseItem)
          }
          if (this.mode === 'point' && responseItem.file.geoJsonURL) {
            responseData.push(responseItem)
          }
        })
        if (this.mode === 'raster') {
          this.$store.commit('setStateProperty', { property: 'rasterDatasets', value: responseData })
        }
        if (this.mode === 'point') {
          this.$store.commit('setStateProperty', { property: 'pointDatasets', value: responseData })
        }
      }).catch(err => {
        console.log(err.message)
      })
    },
    fetchFolders () {
      const timeStamp = new Date().getTime()
      let foldersQuery = false

      if (this.mode === 'raster') {
        foldersQuery = 'https://api.efficientvineyard.com/folders/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.rasterFolder.id
      }
      if (this.mode === 'point') {
        foldersQuery = 'https://api.efficientvineyard.com/folders/' + this.$store.state.farm.id + '?timestamp=' + timeStamp + '&limit=100&folder=' + this.$store.state.pointFolder.id
      }

      axios.get(foldersQuery).then(response => {
        if (this.mode === 'raster') {
          this.$store.commit('setStateProperty', { property: 'rasterFolders', value: response.data })
        }
        if (this.mode === 'point') {
          this.$store.commit('setStateProperty', { property: 'pointFolders', value: response.data })
        }
      }).catch(err => {
        console.log(err.message)
      })
    },
    launchFolder (folder, fromCrumb) {
      if (this.mode === 'raster') {
        if (this.rasterBreadcrumbs.length < 1) {
          this.rasterBreadcrumbs = [{ name: 'Root', id: 'root' }]
        }
        if (fromCrumb) {
          const newBreadcrumbs = []
          this.rasterBreadcrumbs.some(crumb => {
            if (crumb.id !== folder.id) {
              newBreadcrumbs.push(crumb)
            } else {
              newBreadcrumbs.push(crumb)
              return true
            }
          })
          this.rasterBreadcrumbs = newBreadcrumbs
        } else {
          this.rasterBreadcrumbs.push(folder)
        }
        this.$store.commit('setStateProperty', { property: 'rasterFolder', value: folder })
      }
      if (this.mode === 'point') {
        if (this.pointBreadcrumbs.length < 1) {
          this.pointBreadcrumbs = [{ name: 'Root', id: 'root' }]
        }
        if (fromCrumb) {
          const newBreadcrumbs = []
          this.pointBreadcrumbs.some(crumb => {
            if (crumb.id !== folder.id) {
              newBreadcrumbs.push(crumb)
            } else {
              newBreadcrumbs.push(crumb)
              return true
            }
          })
          this.pointBreadcrumbs = newBreadcrumbs
        } else {
          this.pointBreadcrumbs.push(folder)
        }
        this.$store.commit('setStateProperty', { property: 'pointFolder', value: folder })
      }
      this.fetchDatasets()
      this.fetchFolders()
    },
    launchDataset (dataset) {
      if (this.mode === 'raster') {
        this.$store.commit('setStateProperty', { property: 'rasterDataset', value: dataset })
        this.mapRaster()
      }
      if (this.mode === 'point') {
        this.$store.commit('setStateProperty', { property: 'pointDataset', value: dataset })
        this.mapPoints()
      }
    },
    mapPoints () {
      if (this.$store.state.mapObject.getLayer('pointLayer')) {
        this.$store.state.mapObject.removeLayer('pointLayerOutline')
        this.$store.state.mapObject.removeLayer('pointLayer')
        this.$store.state.mapObject.removeSource('pointSource')
      }
      this.$store.state.mapObject.addSource('pointSource', {
        type: 'geojson',
        data: this.$store.state.pointDataset.file.geoJsonURL
      })
      this.$store.state.mapObject.addLayer({
        id: 'pointLayerOutline',
        type: 'circle',
        source: 'pointSource',
        layout: {},
        paint: {
          'circle-radius': 3,
          'circle-color': 'white'
        }
      })
      this.$store.state.mapObject.addLayer({
        id: 'pointLayer',
        type: 'circle',
        source: 'pointSource',
        layout: {},
        paint: {
          'circle-radius': 2,
          'circle-color': 'red'
        }
      })
    },
    mapRaster () {
      console.log('Mapping Raster')
      if (this.$store.state.mapObject.getLayer('rasterLayer')) {
        this.$store.state.mapObject.removeLayer('rasterLayer')
        this.$store.state.mapObject.removeSource('rasterSource')
      }
      this.$store.state.mapObject.addSource('rasterSource', {
        type: 'image',
        url: this.$store.state.rasterDataset.file.jpgURL,
        coordinates: [
          [this.$store.state.rasterDataset.file.xMin, this.$store.state.rasterDataset.file.yMax], // top left
          [this.$store.state.rasterDataset.file.xMax, this.$store.state.rasterDataset.file.yMax], // top right
          [this.$store.state.rasterDataset.file.xMax, this.$store.state.rasterDataset.file.yMin], // bottom right
          [this.$store.state.rasterDataset.file.xMin, this.$store.state.rasterDataset.file.yMin] // bottom left
        ]
      })
      this.$store.state.mapObject.addLayer({
        id: 'rasterLayer',
        source: 'rasterSource',
        type: 'raster',
        paint: {
          'raster-opacity': 0.85
        }
      })
      this.$store.state.mapObject.fitBounds([
        [this.$store.state.rasterDataset.file.xMin, this.$store.state.rasterDataset.file.yMin],
        [this.$store.state.rasterDataset.file.xMax, this.$store.state.rasterDataset.file.yMax]
      ], {
        animate: false
      })
    }
  }
}
</script>

<style scoped>
.card > .list-group {
  border-top: none;
  border-bottom: none;
}
.folder {
  background-color: #eee;
}

.badge.clickable:hover {
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
}
</style>
