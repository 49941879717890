<template>
  <div id="options">
    <div class="container pt-4">
      <!-- RASTER SOURCE SELECT -->
      <!-- <p class="mb-1"><strong>Select Raster Source</strong></p>
      <b-form-select v-model="rasterSource" :options="rasterSourceOptions"></b-form-select> -->

      <!-- myEV RASTER PICKER -->
      <div v-if="rasterSource === 'myEV'">
        <p class="mb-1"><strong>Select myEV Raster Dataset</strong></p>
        <DatasetPicker :mode="'raster'" v-if="$store.state.farm.id && !$store.state.rasterDataset.name" />
        <div
          class="list-group-item list-group-item-action mb-2"
          style="background-color:lightgreen;"
          v-if="$store.state.rasterDataset.name"
        >
          <p class="lead mb-1">{{ $store.state.rasterDataset.name }}</p>
          <i
            class="fas fa-times-circle clickable"
            id="remove-dataset"
            @click="removeDataset('raster')"
          ></i>
        </div>
      </div><!-- /.if rasterSource is myEV -->

      <!-- myEV POINT DATASET PICKER -->
      <p class="mb-1 mt-4"><strong>Select Point Dataset</strong></p>
      <DatasetPicker :mode="'point'" v-if="$store.state.farm.id && !$store.state.pointDataset.name" />
      <div
        class="list-group-item list-group-item-action mb-2"
        style="background-color:lightgreen;"
        v-if="$store.state.pointDataset.name"
      >
        <p class="lead mb-1">{{ $store.state.pointDataset.name }}</p>
        <i
          class="fas fa-times-circle clickable"
          id="remove-dataset"
          @click="removeDataset('point')"
        ></i>
      </div>

      <!-- PLANET.COM SETTINGS -->
      <div v-if="rasterSource === 'planet'">
        <p class="mb-1 mt-4"><strong>Planet API Key</strong></p>
        <b-form-input v-model="planetAPIKey" placeholder="Planet API Key"></b-form-input>
        <b-btn @click="queryPlanet()" class="mt-1">Query Planet Imagery</b-btn>
      </div><!-- v-if raster source === planet -->
      <!-- END PLANET SETTINGS -->

      <!-- FINAL OPTIONS -->
      <p class="mb-1 mt-4"><strong>Neighborhood Setting</strong> <small><i class="fas fa-question-circle" v-b-tooltip.hover title="Neighborhoods are square polygons with widths based on this setting. For example, a 3 meter neighborhood will analyze a 3x3 meter square centered on each datapoint."></i></small></p>
      <b-form-select v-model="neighborhoodSize" :options="neighborhoodSizeOptions"></b-form-select>
      <!-- END OPTIONS -->
      <b-btn
        v-if="$store.state.rasterDataset.name && $store.state.pointDataset.name && !analyzing"
        variant="primary"
        @click="analyzeRaster()"
        class="mt-4 w-100"
      >Analyze Raster</b-btn>
      <b-btn
        v-if="analyzing"
        variant="light"
        class="mt-4 w-100"
      ><i class="fas fa-sync fa-spin"></i> Analyzing now...</b-btn>

      <div v-if="$store.state.analyzedGeoJson">
        <p class="mb-1 mt-4 text-success"><strong>Success!</strong></p>
        <b-btn
          @click="$store.dispatch('download')"
          class="mr-1"
        >Download</b-btn>
        <b-btn
          v-if="!$store.state.posting"
          @click="$store.dispatch('post')"
          variant="success"
        >Add to myEV</b-btn>
        <b-btn
          v-if="$store.state.posting"
          variant="light"
        ><i class="fas fa-sync fa-spin"></i> Adding now...</b-btn>
      </div><!-- /v-if analyzed geojson -->
    </div><!-- /.container -->
  </div><!-- /#options -->
</template>

<script>
import DatasetPicker from './DatasetPicker'
import geoblaze from 'geoblaze'
import axios from 'axios'
import proj4 from 'proj4'
const epsg = require('epsg')
const turf = require('@turf/turf')

export default {
  data () {
    return {
      analyzing: false,
      neighborhoodSize: 3,
      neighborhoodSizeOptions: [
        { value: 0, text: 'No Neighborhood (Just analyze the points)' },
        { value: 1, text: '1 Meter' },
        { value: 3, text: '3 Meter' },
        { value: 5, text: '5 Meter' },
        { value: 10, text: '10 Meter' },
        { value: 30, text: '30 Meter' },
        { value: 50, text: '50 Meter' }
      ],
      planetAPIKey: '',
      rasterSource: 'myEV',
      rasterSourceOptions: [
        { value: 'myEV', text: 'myEV' },
        { value: 'planet', text: 'Planet.com' }
      ]
    }
  },
  components: {
    DatasetPicker
  },
  methods: {
    async queryPlanet () {
      // Checks --> API Key and Points dataset selected
      if (!this.$store.state.pointDataset.file) {
        this.$store.commit('setAlert', { active: true, message: 'You must first select a points dataset.', level: 'alert-warning', timeout: 6000 })
      } else if (!this.planetAPIKey) {
        this.$store.commit('setAlert', { active: true, message: 'You must enter a Planet.com API Key.', level: 'alert-warning', timeout: 6000 })
      } else {
        const pointsGeoJsonFile = await axios.get(this.$store.state.pointDataset.file.geoJsonURL)
        const pointsGeoJson = pointsGeoJsonFile.data
        const pointsBBox = turf.bbox(pointsGeoJson)
        const pointsBBoxGeoJson = turf.bboxPolygon(pointsBBox)
        console.log(pointsBBoxGeoJson)
        axios.get('https://api.planet.com/data/v1/quick-search?_sort=acquired asc&_page_size=50', {
          params: {
            // api_key: this.planetAPIKey,
            // filter: {
            //   type: 'GeometryFilter',
            //   field_name: 'geometry',
            //   config: pointsBBoxGeoJson.geometry
            // }
          }
        }).then(planetDataResponse => {
          console.log(planetDataResponse)
        })
      }
    },
    removeDataset (mode) {
      if (mode === 'raster') {
        this.$store.commit('setStateProperty', { property: 'rasterDataset', value: {} })
        if (this.$store.state.mapObject.getLayer('rasterLayer')) {
          this.$store.state.mapObject.removeLayer('rasterLayer')
          this.$store.state.mapObject.removeSource('rasterSource')
        }
      }
      if (mode === 'point') {
        this.$store.commit('setStateProperty', { property: 'pointDataset', value: {} })
        if (this.$store.state.mapObject.getLayer('pointLayer')) {
          this.$store.state.mapObject.removeLayer('pointLayerOutline')
          this.$store.state.mapObject.removeLayer('pointLayer')
          this.$store.state.mapObject.removeSource('pointSource')
        }
      }
    },
    async analyzeRaster () {
      console.log('Analyzing')
      this.analyzing = true
      // Load raster file
      const rasterFile = this.$store.state.rasterDataset.file.rasterURL
      const raster = await geoblaze.load(rasterFile)
      console.log(raster)
      // Load point file
      const pointsFile = this.$store.state.pointDataset.file.geoJsonURL
      const pointsGeoJson = await axios.get(pointsFile)
      console.log(pointsGeoJson)
      const analyzedGeoJson = {
        type: 'FeatureCollection',
        features: []
      }
      // Analyze each point
      pointsGeoJson.data.features.forEach(feature => {
        let featureCoordinates
        if (raster.projection === 4326) {
          featureCoordinates = feature.geometry.coordinates
        } else {
          const firstProjection = epsg['EPSG:4326']
          const secondProjection = epsg['EPSG:' + raster.projection]
          featureCoordinates = proj4(firstProjection, secondProjection, feature.geometry.coordinates)
        }
        let analyzedPointData
        if (this.neighborhoodSize === 0) {
          // If no neighborhood then just identify the pixel value
          analyzedPointData = geoblaze.identify(raster, featureCoordinates)
        } else {
          const radius = (this.neighborhoodSize / 2) / 1000
          const circle = turf.circle(feature.geometry.coordinates, radius, { steps: 4, units: 'kilometers' })
          const bounds = turf.bbox(circle)
          // CONVERT BASED ON PROJECTION
          if (raster.projection === 4326) {
            analyzedPointData = geoblaze.mean(raster, bounds)
          } else {
            const firstProjection = epsg['EPSG:4326']
            const secondProjection = epsg['EPSG:' + raster.projection]
            const boundsMins = proj4(firstProjection, secondProjection, [bounds[0], bounds[1]])
            const boundsMaxs = proj4(firstProjection, secondProjection, [bounds[2], bounds[3]])
            const newBounds = [boundsMins[0], boundsMins[1], boundsMaxs[0], boundsMaxs[1]]
            analyzedPointData = geoblaze.mean(raster, newBounds)
          }
        }
        const analyzedProperties = Object.assign({}, analyzedPointData)
        console.log(analyzedProperties)
        const analyzedFeature = {
          type: 'Feature',
          properties: analyzedProperties,
          geometry: feature.geometry
        }
        analyzedGeoJson.features.push(analyzedFeature)
      })
      this.$store.commit('setStateProperty', { property: 'analyzedGeoJson', value: analyzedGeoJson })
      this.analyzing = false
    }
  }
}
</script>

<style scoped>
#options {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  z-index: 0;
  overflow-y: scroll;
  padding-bottom: 50px;
}

#remove-dataset {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 600px) {
  #options {
    top: 60%;
    height: 40%;
    width: 100%;
  }
}
</style>
