import axios from 'axios'

export default {
  post ({ commit, state }) {
    commit('setStateProperty', { property: 'posting', value: true })
    const postName = 'RasterAnalysis_' + state.pointDataset.name
    const postGeoJson = JSON.stringify(state.analyzedGeoJson)
    const postObject = {
      name: postName,
      createdBy: 'raster_analyzer',
      farmId: state.farm.id,
      geoJson: postGeoJson
    }
    axios.post('https://api.efficientvineyard.com/post/geojson', postObject).then(response => {
      if (response.data.success) {
        commit('setStateProperty', { property: 'posting', value: false })
        commit('setAlert', { active: true, message: 'This new dataset has been added to your myEV farm!', level: 'alert-success', timeout: 4000 })
      } else {
        commit('setAlert', { active: true, message: 'There was an issue posting your grid to myEV. The dataset may have been too large. Consider downloading the grid directly and manually uploading to myEV.', level: 'alert-danger', timeout: 8000 })
      }
    }).catch(err => {
      console.log(err)
      commit('setAlert', { active: true, message: 'There was an issue posting your grid to myEV. The dataset may have been too large. Consider downloading the grid directly and manually uploading to myEV.', level: 'alert-danger', timeout: 12000 })
    })
  },
  download ({ commit, state }) {
    let fileContents = JSON.stringify(state.analyzedGeoJson)
    fileContents = [fileContents]
    const blob = new Blob(fileContents, { type: 'text/plain;charset=utf-8' })
    const url = window.URL || window.webkitURL
    const link = url.createObjectURL(blob)
    const a = document.createElement('a')
    a.download = 'rasterAnalyzed.geojson'
    a.href = link
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  reset ({ commit, state }) {
    if (state.mapObject.getLayer('rasterLayer')) {
      state.mapObject.removeLayer('rasterLayer')
      state.mapObject.removeSource('rasterSource')
    }
    commit('setStateProperty', { property: 'dataset', value: {} })
    commit('setStateProperty', { property: 'analyzedGeoJson', value: false })
    const lat = state.farm.geolocation._latitude
    const lng = state.farm.geolocation._longitude
    state.mapObject.flyTo({
      center: [lng, lat],
      zoom: 15,
      animate: false
    })
  },
  initRasterMap ({ commit, state }) {
    mapboxgl.accessToken = 'pk.eyJ1IjoibGVyZ3AiLCJhIjoiY2p4bmI1NzNzMGN0MTNjcGx4cjF4eDBtdSJ9.2C0FEHhNZ-jGd7jgIRTrEQ' // eslint-disable-line
    state.mapObject = new mapboxgl.Map({ // eslint-disable-line
      container: 'rasterMap',
      // style: 'mapbox://styles/mapbox/satellite-v9',
      style: 'https://api.maptiler.com/maps/da520b6d-739a-4646-888f-1d3f2b664f78/style.json?key=IuJ0D0R9S0Cjl4krhvr1',
      center: [-95, 40],
      zoom: 3
    })

    state.mapObject.addControl(new mapboxgl.NavigationControl()) // eslint-disable-line
    // WHEN MAP LOADS SETUP BLOCKS
    state.mapObject.on('load', function () {
      const lat = state.farm.geolocation._latitude
      const lng = state.farm.geolocation._longitude
      state.mapObject.flyTo({
        center: [lng, lat],
        zoom: 15,
        animate: false
      })
    }) // map.on load
  },
  initPlugin ({ commit, state, dispatch }, farmId) {
    commit('setStateProperty', { property: 'spinner', value: true })
    const timeStamp = new Date().getTime()
    const url = 'https://api.efficientvineyard.com/farm/' + farmId + '?timestamp=' + timeStamp
    axios.get(url).then(response => {
      response.data.id = farmId
      commit('setStateProperty', { property: 'farm', value: response.data })
      commit('setStateProperty', { property: 'spinner', value: false })
      dispatch('initRasterMap')
      return true
    })
  }
}
